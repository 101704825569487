/* ----------------------------------------
    Imports
---------------------------------------- */

import anime from "animejs";
import { isDescendant } from "./../helpers/Helpers";

/* ----------------------------------------
    Nav dropdown
---------------------------------------- */

export class NavDropdown {
    constructor(settings) {
        this._settings = settings;

        this._wrapperEl = document.querySelector(settings.wrapper);

        if (!this._wrapperEl) return;

        this._triggerEl = this._wrapperEl.querySelector(settings.trigger);
        this._el = this._wrapperEl.querySelector(settings.el);

        if (!this._el || !this._triggerEl) return;

        this._handleDocumentEvent = this._handleDocumentEvent.bind(this);
        this._active = false;

        this._triggerEl.classList.remove("active");

        this._init();
    }

    /** ----------------------------------------
        Document events
     ---------------------------------------- */

    _listenDocumentEvent() {
        document.addEventListener("click", this._handleDocumentEvent, false);
    }

    _removeDocumentEvent() {
        document.removeEventListener("click", this._handleDocumentEvent, false);
    }

    _handleDocumentEvent(event) {
        if (
            !isDescendant(this._wrapperEl, event.srcElement) &&
            this._wrapperEl !== event.srcElement
        )
            this.close();

        return this;
    }

    /** ----------------------------------------
        Animations
     ---------------------------------------- */

    _showAnimation() {
        anime({
            targets: this._el,
            opacity: [0, 1],
            transformY: [15, 0],
            easing: "easeOutQuad",
            duration: 250,
            begin: () => {
                this._el.style.display = "flex";
            },
        });
    }

    _hideAnimation() {
        anime({
            targets: this._el,
            opacity: [1, 0],
            transformY: [0, 15],
            easing: "easeOutQuad",
            duration: 250,
            complete: () => {
                this._el.style.display = "none";
            },
        });
    }

    open() {
        this._active = true;
        this._listenDocumentEvent();
        this._showAnimation();
    }

    close() {
        this._active = false;
        this._removeDocumentEvent();
        this._hideAnimation();
    }

    _events() {
        this._triggerEl.addEventListener("click", (e) => {
            // toggle classList
            this._triggerEl.classList.toggle("active");
            e.preventDefault();

            this._active ? this.close() : this.open();
        });
    }

    _init() {
        this._events();
    }
}
