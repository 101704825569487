<template>
    <a :href="data.link" class="card thread-card threads__row">

        <!-- AVATAR -->
        <div class="thread-card__avatar">
            <span class="avatar avatar--large">
                <img class="avatar__image" v-if="hasAvatar(data.author.avatar)" :src="data.author.avatar">
                <span class="avatar__initials" v-if="!hasAvatar(data.author.avatar)">{{ data.author.avatar }}</span>
            </span>
        </div>

        <!-- CONTENT -->
        <div class="thread-card__content">
            <div class="card__title thread-card__title">{{ data.title }}</div>
            <div class="card__meta--dots thread-card__meta  meta--small">
                <span class="meta__author">{{ data.author.name }}</span>
                <span class="meta__time meta--sep-dash">{{ time(data.time) }}</span>
                <span class="meta__comments meta--sep-dash">{{ data.comments > 0 ? data.comments : 'Geen' }}
                    reacties</span>
                <span class="meta__label meta--sep-dot" :class="getColor(data.theme.color)">{{ data.theme.title
                    }}</span>
            </div>
        </div>

        <!-- COMMENTS -->
        <div class="thread-card__comments">
            <div class="avatar__collection">
                <span class="avatar avatar--small" v-for="(author, index) in data.authors.avatars"
                    :style="setIndex(index)">
                    <img class="avatar__image" v-if="hasAvatar(author)" :src="author">
                    <span class="avatar__initials" v-if="!hasAvatar(author)">{{ author }}</span>
                </span>
                <div class="avatar avatar--small" v-if="data.authors.total > 0"
                    style="transform: translateX(0); z-index: 1;">
                    <span class="avatar__amount">{{ data.authors.total }}+</span>
                </div>
            </div>
        </div>

    </a>
</template>

<script>

/* ----------------------------------------
    Node Modules
---------------------------------------- */

import moment from 'moment';

/* ----------------------------------------
     Component
 ---------------------------------------- */

export default {

    name: 'Thread',

    /* ----------------------------------------
        Data
    ---------------------------------------- */

    /**
     * If needed set debug to true to display
     * values (ids) of related filter values.
     */

    data() {
        return {
            debug: false,
            now: Date.now()
        }
    },

    /* ----------------------------------------
        Methods
    ---------------------------------------- */

    methods: {

        /* ----------------------------------------
            Get Color
        ---------------------------------------- */

        /**
         * Will receive a color and parse it to a
         * color modifier class used by stylesheet.
         */

        getColor(value) {
            return 'color:' + value;
        },

        /* ----------------------------------------
            Set Index
        ---------------------------------------- */

        /**
         * Will generate styling for author images
         * based on a given offset. Avatars are will
         * presume that only 3 items are added within
         * the data object of the given feed.
         */

        setIndex(index) {
            const length = this.data.authors.avatars.length;
            const total = this.data.authors.total;

            const offset = length === 3 && total > 0 ? 1 : 0;
            const translateX = -Math.abs((length * 1.9 * offset) - (1.9 * index)) + 'em';
            const zIndex = length - index + offset;

            return `transform: translateX(${translateX}); z-index: ${zIndex}`;
        },

        /* ----------------------------------------
            Convert Timestamp
        ---------------------------------------- */

        /**
         * Will convert a UNIX timestamp to a humanized
         * format of time, like 3 days ago.
         */

        time(stamp) {
            moment.locale('nl');
            return moment.duration(stamp - (this.now / 1000), 'seconds').humanize(true);
        },

        /* ----------------------------------------
            Has Avatar
        ---------------------------------------- */

        /**
         * Will check if given string is an image
         */

        hasAvatar(src) {
            if (!src) { return null }
            return src.match(/\.(jpeg|jpg|gif|png)$/) != null;
        }

    },

    /* ----------------------------------------
        Props
    ---------------------------------------- */

    /**
     * The data object contains all the information
     * that has to be displayed within the render
     * of this card element.
     */

    props: ['data']

};
</script>