var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "card thread-card threads__row",
      attrs: { href: _vm.data.link },
    },
    [
      _c("div", { staticClass: "thread-card__avatar" }, [
        _c("span", { staticClass: "avatar avatar--large" }, [
          _vm.hasAvatar(_vm.data.author.avatar)
            ? _c("img", {
                staticClass: "avatar__image",
                attrs: { src: _vm.data.author.avatar },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasAvatar(_vm.data.author.avatar)
            ? _c("span", { staticClass: "avatar__initials" }, [
                _vm._v(_vm._s(_vm.data.author.avatar)),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "thread-card__content" }, [
        _c("div", { staticClass: "card__title thread-card__title" }, [
          _vm._v(_vm._s(_vm.data.title)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card__meta--dots thread-card__meta meta--small" },
          [
            _c("span", { staticClass: "meta__author" }, [
              _vm._v(_vm._s(_vm.data.author.name)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "meta__time meta--sep-dash" }, [
              _vm._v(_vm._s(_vm.time(_vm.data.time))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "meta__comments meta--sep-dash" }, [
              _vm._v(
                _vm._s(_vm.data.comments > 0 ? _vm.data.comments : "Geen") +
                  "\n                reacties"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "meta__label meta--sep-dot",
                class: _vm.getColor(_vm.data.theme.color),
              },
              [_vm._v(_vm._s(_vm.data.theme.title))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "thread-card__comments" }, [
        _c(
          "div",
          { staticClass: "avatar__collection" },
          [
            _vm._l(_vm.data.authors.avatars, function (author, index) {
              return _c(
                "span",
                {
                  staticClass: "avatar avatar--small",
                  style: _vm.setIndex(index),
                },
                [
                  _vm.hasAvatar(author)
                    ? _c("img", {
                        staticClass: "avatar__image",
                        attrs: { src: author },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hasAvatar(author)
                    ? _c("span", { staticClass: "avatar__initials" }, [
                        _vm._v(_vm._s(author)),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm._v(" "),
            _vm.data.authors.total > 0
              ? _c(
                  "div",
                  {
                    staticClass: "avatar avatar--small",
                    staticStyle: { transform: "translateX(0)", "z-index": "1" },
                  },
                  [
                    _c("span", { staticClass: "avatar__amount" }, [
                      _vm._v(_vm._s(_vm.data.authors.total) + "+"),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }